<template>
  <div id="componentactually">
    <h1>A la une :</h1>
    <div id="flex">
      <hooper id="hooperId" :settings="hooperSettings">
        <slide
          v-for="(movie, indx) of dataSlides"
          :key="movie.id"
          :index="indx"
        ><router-link :to="`/film/${movie.id}`">
          <img :src="url + movie.poster_path" alt="" /></router-link>
        </slide>
      </hooper>
    </div>
  </div>
</template>


<script>
import { mapActions, mapMutations } from "vuex";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
export default {
  name: "Actually",
  data() {
    return {
      hooperSettings: {
        playSpeed: "2000",
        vertical: true,
        autoPlay: true,
        centerMode: true,
        itemsToShow: 1,
        infiniteScroll:true,

      },
      url: "http://image.tmdb.org/t/p/w300",
      adress: null,
      filmActu: null,
      dataSlides: null,
      autoPlaying: true,
    };
  },
  methods: {
    ...mapMutations(["setMovies"]), // mapMutations renvoie a mutations dans le store
    ...mapActions(["get"]),
  },
  components: { Hooper, Slide },

  props: {},

  created: async function () {
    let response = await fetch(
      `${this.$store.state.urlAPI}movie/now_playing?${this.$store.state.apiKey}&language=fr-FR&page=1`
    );
    let json = await response.json();
    this.dataSlides = json.results;
    console.log(this.dataSlides);
  },
};
</script>
<style scoped>
h1{
  color: white;
}
#flex {
  display: flex;
  max-width: 100%;
}

#tost {
  max-width: 300px;
}
#componentactually {
  align-content: center;
}
img {
  max-width: 200px;
  height: auto;
}
.is-active {
  min-height: 600px;
}
.hooper {
  min-height: 300px;
}
</style>