<template>
  <div class="relative">
     <navebar></navebar>
   
    <actually class="absolute"></actually>

    <h1 v-if="this.$store.state.movies!=null" class="position">Résultats de la recherche :</h1>
    <div class="flex position">
      
      <div v-for="movie of $store.state.movies" :key="movie.id" >
        <div >
          <router-link :to="{ name: 'Film', params: { id: movie.id } }">
            <img :src="getUrlPict(movie)" alt="" class="taille"/>
          </router-link>
        </div>
        <p class="title largeur">{{ movie.title }}</p>
      </div>
      <div class="actualite">
        
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import actually from "@/components/Actually";
// import { mapActions } from "vuex";
import navebar from "@/components/Navebar";
export default {
  name: "Accueil",
  data() {
    return {
      // urlPict: `http://image.tmdb.org/t/p/w500/`,
    };
  },
  components: { actually, navebar },
  methods: {
    getUrlPict: function (movie) {
      
      return movie.poster_path === null
        ? "./image-non-disponible.png"
        : this.$store.state.urlPict + movie.poster_path;
    },
  },
};
</script>
<style scoped>
.position{
  width: 1200px;
  margin-left: 200px;;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
h1{
  color: white;
}
.largeur{
  width: 170px;
}
.liste {
  color: white;
}
.taille{
  width: 150px;
}
.title {
  font-size: 15px;
  white-space: pre-line;

  color: white;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
img {
  padding: 10px;
}
</style>

